import React from 'react'

const Footer = () => {
    return (
        <>
            <div className="h-36 bg-gray-500 mt-10 ">
                <div className="pt-20">
                </div>
            </div>
        </>
    )

}

export default Footer
