import React from 'react'


const Burger = ({ open, setOpen }) => {
    return (
      <button className="absolute top-3 md:top-8 right-4 flex flex-col justify-around w-8 h-8 bg-transparent border-none cursor-pointer p-0 z-20 focus:outline-none md:hidden" onClick={() => setOpen(!open)}>
        <div className={`w-8 h-1 origin-1px ${open ? 'transform rotate-45' : 'transform rotate-0'} bg-black rounded-xl transition-all duration-300 relative`} />
        <div className={`w-8 h-1 origin-1px ${open ? 'opacity-0 transform translate-x-5' : 'opacity-100 transform translate-x-0'} bg-black rounded-xl transition-all duration-300 relative`}/>
        <div className={`w-8 h-1 origin-1px ${open ? 'transform -rotate-45' : 'transform rotate-0'} bg-black rounded-xl transition-all duration-300 relative`}/>
      </button>
    )
}

export default Burger