/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import Navigation from "./navigation"
import Footer from "./footer"


const Layout = ({ children }) => {


  return (
    <>

          <div className="mx-auto pb-10 font-roboto">
            <Navigation/>        
            {children}
          </div>
          <Footer/>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
